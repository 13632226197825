import React, { Suspense, useMemo } from "react";
import { RequestPanelProps } from "./request";
import { Loader } from "./components/loader";
import { ResponseBodyProps } from "./response-body";

export const LazyRequestsPanel: React.FC<RequestPanelProps> = (props) => {
  const RequestPanel = useMemo(() => React.lazy(() => import("./request")), []);
  return (
    <Suspense fallback={<Loader url={props.url} isTailing={props.isTailing} />}>
      <RequestPanel {...props} />
    </Suspense>
  );
};

export const LazyResponseBody: React.FC<ResponseBodyProps> = (props) => {
  const ResponseBody = useMemo(
    () => React.lazy(() => import("./response-body")),
    [],
  );
  return (
    <Suspense>
      <ResponseBody {...props} />
    </Suspense>
  );
};
