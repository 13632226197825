import styles from "./button.module.css";
import React, { CSSProperties } from "react";

export interface ButtonProps {
  onClick: () => void;
  children: string | React.ReactNode | React.ReactNode[];
  style?: CSSProperties;
}

export const Button: React.FC<ButtonProps> = ({ children, onClick, style }) => (
  <button onClick={onClick} style={style}>
    <div onClick={onClick} className={styles.buttonTop}>
      {children}
    </div>
  </button>
);
