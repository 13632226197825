import { useCallback, useEffect, useState } from "react";
import { WebsocketClient } from "./websocket-client";
import { useBaseUrl } from "./utils/get-api-base-url";
import {
  ChannelId,
  HooklookMessage,
  RequestData,
  ResponseConfig,
} from "@hooklook/model";

export interface HookState {
  baseUrl: string;
  responseConfig: ResponseConfig;
  error: string | null;
  loading: boolean;
  socket: WebsocketClient<HooklookMessage> | null;
  channelId: ChannelId;
  requests: RequestData[];
}

export interface SetHookState {
  mergeResponseConfig: (update: Partial<ResponseConfig>) => void;
}

export const useRequestData = (
  channelId: ChannelId,
): [HookState, SetHookState] => {
  const { http, ws } = useBaseUrl();

  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(true);
  const [responseConfig, setResponseConfig] = useState<ResponseConfig>({
    responseStatus: 404,
    state: "running",
    contentType: null,
    responseValue: null,
  });
  const [socket, setSocket] = useState<WebsocketClient<HooklookMessage> | null>(
    null,
  );
  const [requests, setRequests] = useState<RequestData[]>([]);

  const onIncomingMessage = useCallback(
    (data: HooklookMessage) => {
      switch (data.type) {
        case "config":
          setResponseConfig(data.value);
          break;
        case "request":
          setRequests((requests) => [...requests, data.value]);
          break;
      }
    },
    [setResponseConfig, setRequests],
  );

  useEffect(() => {
    let abort = false;

    const setup = async () => {
      const response = await fetch(`/api/${channelId}`);

      if (abort) {
        return;
      }

      if (!response.ok) {
        setError("request failed");
        return;
      }

      const config: ResponseConfig = await response.json();
      setResponseConfig(config);

      if (abort) {
        return;
      }

      const socket = new WebsocketClient<HooklookMessage>(
        ws,
        channelId,
        onIncomingMessage,
      );
      setSocket(socket);
      setLoading(false);
    };

    setup();
    return () => {
      abort = true;
    };
  }, [setSocket, setLoading]);

  const setResponseConfigWithRemote = useCallback(
    (value: Partial<ResponseConfig>) => {
      setResponseConfig((state) => {
        const newState = {
          ...state,
          ...value,
        };

        socket?.send({
          type: "config",
          value: newState,
        });

        return newState;
      });
    },
    [setResponseConfig, socket],
  );

  return [
    {
      baseUrl: http,
      responseConfig,
      error,
      loading,
      socket,
      channelId,
      requests: requests.slice(0, 10),
    },
    {
      mergeResponseConfig: setResponseConfigWithRemote,
    },
  ];
};
