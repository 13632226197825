import React, { useCallback, useState } from "react";
import styles from "./copy-code.module.css";
import { Popover } from "react-tiny-popover";

export interface CopyCodeProps {
  children?: string;
}

export const CopyCode: React.FC<CopyCodeProps> = ({ children }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClick = useCallback(() => {
    let aborted = false;

    if (!children) {
      return;
    }

    navigator.clipboard.writeText(children).catch(console.error);
    setIsPopoverOpen(true);

    setTimeout(() => aborted || setIsPopoverOpen(false), 500);

    return () => {
      aborted = true;
    };
  }, [children]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      padding={10}
      positions={["bottom"]} // preferred positions by priority
      content={<div className={styles.copied}>Copied!</div>}
    >
      <code onClick={onClick} className={styles.code}>
        {children}
      </code>
    </Popover>
  );
};
