import React from "react";
import { Input } from "./components/input";
import styles from "./app.module.css";
import { Toggle } from "./components/toggle";
import { useCallback } from "react";
import { useRequestData } from "./use-data";
import { CopyUrlButton } from "./components/copy-url-button";
import { Footer } from "./components/footer";
import { getRouteChannel } from "./utils/get-route-channel";
import { Loader } from "./components/loader";
import { LazyRequestsPanel } from "./async-components";
import { ResponsePanel } from "./response";
import { SectionTitle } from "./components/section-title";

export const App: React.FC = () => {
  const [
    { loading, error, requests, channelId, socket, baseUrl, responseConfig },
    { mergeResponseConfig },
  ] = useRequestData(getRouteChannel());

  const toggleHttpResponseStatus = useCallback(
    (enabled: boolean) => {
      mergeResponseConfig({
        responseStatus: enabled ? 200 : 404,
      });
    },
    [mergeResponseConfig],
  );

  const url = `${baseUrl}/${channelId}`;
  const startingUp = loading || !socket?.connected;
  const enabled = responseConfig.responseStatus === 200;

  if (startingUp && !error) {
    return null;
  }

  const isTailing = responseConfig.state === "running";

  return (
    <div className={styles.container}>
      <div className="flex">
        <Toggle
          enabled={enabled}
          setEnabled={toggleHttpResponseStatus}
          labels={["HTTP 404", "HTTP 200"]}
        />
        <Input disabled value={url} />
        <CopyUrlButton value={url} />
      </div>
      {error ? (
        <>
          <div className={styles.divider} />
          <SectionTitle title="Rate limited" subtitle="Too many users." />
          <p>
            There&#39;s too many people connected to this hook channel.
            <br />
            Right now Hooklook only supports 3 concurrent users configuring a
            channel.
            <br />
            <br />
            Reach out to <code>spelexander93@gmail.com</code> if you&#39;d like
            more.
          </p>
        </>
      ) : (
        <>
          <div className={styles.divider} />
          <ResponsePanel
            mergeResponseConfig={mergeResponseConfig}
            responseConfig={responseConfig}
          />
          <div className={styles.divider} />
          {requests.length === 0 ? (
            <Loader url={url} isTailing={isTailing} />
          ) : (
            <LazyRequestsPanel
              url={url}
              requests={requests}
              isTailing={isTailing}
            />
          )}
        </>
      )}
      <Footer />
    </div>
  );
};
