import React, { ChangeEvent, useCallback, useMemo } from "react";
import styles from "./copy-code.module.css";

export interface SelectProps {
  values: string[];
  selected: string;
  setSelected: (value: string) => void;
}

export const Select = ({ values, selected, setSelected }: SelectProps) => {
  const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
  }, []);

  const selectableItems = useMemo(() => {
    return values.map((value, index) => (
      <option key={`${index}`} value={value}>
        {String(value)}
      </option>
    ));
  }, [values]);

  return (
    <>
      <select value={selected} className={styles.select} onChange={onChange}>
        {selectableItems}
      </select>
    </>
  );
};
