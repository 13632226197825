import React, { ChangeEvent, useCallback } from "react";

import styles from "./toggle.module.css";
import { testId } from "../utils/data-test-id";

export interface ToggleProps {
  labels: [string, string];
  setEnabled: (value: boolean) => void;
  enabled: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  enabled,
  setEnabled,
  labels,
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEnabled(event.target.checked);
    },
    [setEnabled],
  );

  const [on, off] = labels;

  return (
    <div className="flex">
      <span className={styles.toggleLabels}>{on}</span>
      <label className={styles.switch}>
        <input
          className={styles.checkInput}
          type="checkbox"
          onChange={onChange}
          checked={enabled}
        />
        <span
          data-testid={testId("response-toggle")}
          className={styles.slider}
        />
      </label>
      <span className={styles.toggleLabels}>{off}</span>
    </div>
  );
};
